import Popover from '@material-ui/core/Popover';
import { Dropdown, Menu } from 'antd';
import * as _ from 'lodash';
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/it';
import React, { PureComponent } from 'react';
import Tappable from 'react-tappable';

import env from '../../../../config/env';
import appService, { fetchMe } from '../../api/api/appService';
import jwtService from '../../api/api/jwtService';
import AppointmentTimePicker from '../../components/AppointmentTimePicker';
import SVGIcon from '../../components/SVGIcon';
import { EMBED_STEPS } from '../../constants/authModalTypes';
import { SmallDownArrowIcon, SmallUpArrowIcon } from '../../constants/icons';
import { setDefaultChosenService } from '../../utils/booking';
import CheckoutContainer from '../CheckoutContainer/CheckoutContainer';
import EmbedError from '../EmbedAuthModals/EmbedError';
import EmbedCheckoutContainer from '../EmbedCheckoutContainer/EmbedCheckoutContainer';
import ProfileHeader from '../ProfileHeader';
import styles from './styles';
import stylesSmall from './stylesSmall';

const DISPLAYING_WIDGET = {
  BOOKING_CALENDAR: 'BOOKING_CALENDAR',
  BOOKING_CONFIRM: 'BOOKING_CONFIRM',
};

class EmbedBookingContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      chosenProvider: null,
      providers: this.props.providers,
      chosenService: null,
      services: [],
      employeeDropDown: false,
      serviceDropDown: false,
      user: null,
      showErrorModal: false,
      errorMessage: '',
      displayingWidget: DISPLAYING_WIDGET.BOOKING_CALENDAR,
      timeSlot: null,
      date: null,
      queryData: {},
      userId: '',
      activeStep: 0,
      signUpDataStep1: {},
    };

    this.toggleDropDownEmployee = this.toggleDropDownEmployee.bind(this);
    this.toggleDropDownService = this.toggleDropDownService.bind(this);
    this.setChosenProvider = this.setChosenProvider.bind(this);
    this.setChosenService = this.setChosenService.bind(this);
    this.getFilteredProviderList = this.getFilteredProviderList.bind(this);
    this.setServiceList = this.setServiceList.bind(this);
    this.bookAppointment = this.bookAppointment.bind(this);
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    const { providers, preselectedProviderId } = this.props;
    if (
      _.isArray(providers)
      && !_.isEmpty(providers)
      && preselectedProviderId
    ) {
      this.setState(
        {
          chosenProvider:
            _.find(
              providers,
              (provider) => provider.centreProvider_id === preselectedProviderId,
            ) || providers[0],
        },
        this.setServiceList,
      );
    } else if (_.isArray(providers) && !_.isEmpty(providers)) {
      this.setState(
        {
          chosenProvider: providers[0],
        },
        this.setServiceList,
      );
    }
  }

  async componentDidMount() {
    let user;
    try {
      user = await fetchMe();
    } catch (error) {
      console.log(error);
    }

    if (user) {
      moment.locale(user.UserSetting.language);
      this.setState({ user });
    }
  }

  handleNext = () => {
    this.setState((state) => ({
      activeStep: state.activeStep + 1,
    }));
  };

  handleBack = () => {
    this.setState((state) => ({
      activeStep: state.activeStep - 1,
    }));
  };

  handleStepChange = (step) => {
    this.setState({
      activeStep: step,
    });
  };

  // eslint-disable-next-line react/sort-comp
  toggleDropDownEmployee() {
    this.setState((prevState) => ({
      employeeDropDown: !prevState.employeeDropDown,
      serviceDropDown: false,
    }));
  }

  toggleDropDownService() {
    this.setState((prevState) => ({
      serviceDropDown: !prevState.serviceDropDown,
      employeeDropDown: false,
    }));
  }

  setChosenProvider(provider) {
    this.setState(
      {
        chosenProvider: provider,
        employeeDropDown: false,
      },
      () => {
        this.setServiceList();
      },
    );
  }

  setChosenService(service) {
    this.setState({
      chosenService: service,
      serviceDropDown: false,
    });
  }

  // todo is used multiple times
  async setServiceList() {
    const { state, props } = this;
    if (_.isEmpty(state.chosenProvider)) {
      this.setState({ services: [] });
      return;
    }

    const serviceProviderVariants = _.filter(
      props.serviceVariants,
      (service) => service.centreProvider_fk === state.chosenProvider.centreProvider_id,
    );

    _.map(serviceProviderVariants, (service) => {
      if (service.name.includes(', pro 5 Minuten')) {
        // eslint-disable-next-line no-param-reassign,prefer-destructuring
        service.name = service.name.split(', pro 5 Minuten')[0];
      }
    });

    this.setState({ services: serviceProviderVariants }, () => this.setState((prevState) => setDefaultChosenService(
      this.props.preselectedServiceId,
      prevState.chosenProvider?.isManualSorting,
      prevState.chosenService,
      prevState.services,
    )));
  }

  getFilteredProviderList(providers) {
    return _.filter(
      providers,
      (provider) => provider.centreProvider_id
        !== this.state.chosenProvider.centreProvider_id,
    );
  }

  getFilteredSortedServiceList(services) {
    const filteredList = _.filter(
      services,
      (service) => this.state.chosenService
        && service.service_id !== this.state.chosenService.service_id
        && service.onlineBooking
        // eslint-disable-next-line no-underscore-dangle
        && service.providerId === this.state.chosenProvider._id,
    );

    return _.sortBy(
      filteredList,
      this.state.chosenProvider?.isManualSorting ? 'order' : 'name',
    );
  }

  onSignInSubmit = async (signInData) => {
    try {
      const { email, password } = signInData;

      if (!email || !password) {
        return;
      }

      // eslint-disable-next-line import/no-named-as-default-member
      const { signInToken, refreshToken } = await appService.signIn(signInData);

      jwtService.setAccessToken(signInToken);
      jwtService.setRefreshToken(refreshToken);

      const user = await fetchMe();

      this.setState(
        {
          user,
        },
        () => {
          this.bookAppointment(this.state.timeSlot, this.state.date);
        },
      );
    } catch (e) {
      this.errorHandler(e);
    }
  };

  onSignUpSubmitStep1 = (signUpDataStep1) => {
    this.setState({ signUpDataStep1 });
    this.handleStepChange(EMBED_STEPS.PASSWORD);
  };

  onSignUpSubmitStep2 = async (signUpDataStep2) => {
    try {
      let langKey = localStorage.getItem('i18nextLng') || 'de';
      if (langKey.indexOf('-') !== -1) {
        // eslint-disable-next-line prefer-destructuring
        langKey = langKey.split('-')[0];
      }

      const dataToSignUp = {
        ...this.state.signUpDataStep1,
        phone: `${this.state.signUpDataStep1.phone}`.replace(/[ +]/g, ''),
        password: signUpDataStep2.password,
        langKey,
      };

      // eslint-disable-next-line import/no-named-as-default-member
      const signUpRequest = appService.signUp;

      const { user } = await signUpRequest(dataToSignUp);

      this.setState(
        {
          userId: user.user_id,
        },
        () => {
          this.handleStepChange(EMBED_STEPS.VERIFICATION);
        },
      );
    } catch (e) {
      this.errorHandler(e);
    }
  };

  onVerificationSubmit = async () => {
    try {
      // eslint-disable-next-line import/no-named-as-default-member
      await appService.sendActivationCode(this.state.userId);
    } catch (e) {
      this.errorHandler(e);
    }
  };

  onSendCodeSubmit = async (form) => {
    try {
      const { code } = form;
      let langKey = localStorage.getItem('i18nextLng') || 'de';
      if (langKey.indexOf('-') !== -1) {
        // eslint-disable-next-line prefer-destructuring
        langKey = langKey.split('-')[0];
      }
      // eslint-disable-next-line import/no-named-as-default-member
      const { signInToken, refreshToken } = await appService.activateWithCode({
        code,
        userId: this.state.userId,
        langKey,
      });

      jwtService.setAccessToken(signInToken);
      jwtService.setRefreshToken(refreshToken);

      const user = await fetchMe();

      this.setState(
        {
          user,
          userId: null,
        },
        () => {
          this.handleStepChange(EMBED_STEPS.CONFIRMATION);
        },
      );
    } catch (e) {
      this.errorHandler(e);
    }
  };

  errorHandler = (e) => {
    const {
      status,
      data: { error },
    } = e.response;
    if (status >= 400 && status < 500) {
      if (error.userId && error.isSmsAttemptsExhausted) {
        this.setState({
          userId: error.userId,
        });
        return;
      }

      if (error.userId) {
        this.setState({
          userId: error.userId,
        });
        return;
      }
    }

    this.setState({
      showErrorModal: true,
      errorMessage:
        typeof error.message === 'string' ? error.message : 'An error occurred',
    });
  };

  renderServiceMenu = () => {
    const filteredSortedServiceList = this.getFilteredSortedServiceList(
      this.state.services,
    );

    return !_.isEmpty(filteredSortedServiceList) ? (
      <Menu>
        {filteredSortedServiceList.map((service, index) => (
          <Menu.Item
            key={`service-${index + 1}`}
            style={{ padding: '5px 8px' }}
          >
            <Tappable onTap={() => this.setChosenService(service)}>
              <ith-div class="ith_dropDownService">
                <ith-p
                  class="ith_dropdownText"
                  style={{ whiteSpace: 'break-spaces' }}
                >
                  {`${service.name} ${service.duration} min`}
                </ith-p>
                <ith-p class="ith_dropdownPrice">
                  {this.getPrice(service)}
                </ith-p>
                <style>{styles}</style>
              </ith-div>
            </Tappable>
          </Menu.Item>
        ))}
      </Menu>
    ) : (
      <Menu />
    );
  };

  // eslint-disable-next-line class-methods-use-this
  getPrice = (service) => {
    const price = Math.round((service.ppu * service.count) / 0.05) * 0.05;
    return price !== 0 ? `${service.currency} ${price}` : '';
  };

  // todo check or complet
  async bookAppointment(timeSlot, date) {
    const {
      chosenService: { duration, bufferTime },
      user,
    } = this.state;

    if (!user) {
      this.handleStepChange(EMBED_STEPS.SIGN_UP_SIGN_IN);
    } else {
      this.handleStepChange(EMBED_STEPS.CONFIRMATION);
    }

    try {
      const { state, props } = this;
      const endTime = moment(timeSlot.startTime, 'HH:mm')
        .add(duration, 'minutes')
        .add(bufferTime, 'minutes')
        .format('HH:mm');

      const { centre } = props;
      const { chosenProvider, chosenService } = state;
      const services = [chosenService];

      this.setState({
        queryData: {
          centre,
          provider: chosenProvider,
          services,
          startTime: timeSlot.startTime,
          endTime,
          bufferTime,
          date,
        },
        displayingWidget: DISPLAYING_WIDGET.BOOKING_CONFIRM,
      });
    } catch (e) {
      console.error(e);
    }
  }

  renderEmployeeMenu = () => {
    const filteredProviderList = this.getFilteredProviderList(
      this.props.providers,
    );

    return !_.isEmpty(filteredProviderList) ? (
      <Menu>
        {filteredProviderList.map((provider, index) => (
          <Menu.Item key={`employee-${index + 1}`}>
            <Tappable onTap={() => this.setChosenProvider(provider)}>
              <ith-div class="ith_dropdownEmployee">
                <ith-div
                  class="ith_employeeNameAvatar"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {provider.User.avatar_fk ? (
                    <img
                      className="ith_employeeAvatar"
                      src={provider.User.avatar_fk}
                      alt="avatar"
                      style={{
                        height: '40px',
                        width: '40px',
                        borderRadius: '100%',
                        marginRight: '10px',
                      }}
                    />
                  ) : (
                    <img
                      className="ith_employeeAvatar"
                      src={`${env.api.consumerApps}/public/imgs/avatar.png`}
                      alt="Avatar"
                      style={{
                        height: '40px',
                        width: '40px',
                        borderRadius: '100%',
                        marginRight: '10px',
                      }}
                    />
                  )}
                  <ith-p class="ith_dropdownText">
                    {provider.User.firstName}
                    {' '}
                    {provider.User.lastName}
                  </ith-p>
                </ith-div>
                <style>{styles}</style>
              </ith-div>
            </Tappable>
          </Menu.Item>
        ))}
      </Menu>
    ) : (
      <Menu />
    );
  };

  render() {
    const { state, props } = this;

    const anchorElement = document.getElementById('booking_container_wrapper');
    const anchorEmbedStepperElement = document.getElementById(
      'ith_embed-auth-stepper',
    );

    return (
      <>
        <ith-div
          id="booking_container_wrapper"
          class="ith_booking_container_wrapper"
          style={
            props.isEmbed && !props.isSmallTemplate ? { padding: '25px' } : {}
          }
        >
          {state.displayingWidget === DISPLAYING_WIDGET.BOOKING_CALENDAR && (
            <>
              <ith-div
                class={
                  this.dynamicBootstrapStyles && !props.isEmbed
                    ? this.dynamicBootstrapStyles.container
                    : 'ith_booking_container'
                }
                style={
                  !this.dynamicBootstrapStyles
                  && props.isEmbed
                  && !props.isSmallTemplate
                    ? { height: '100%', width: '100%' }
                    : { marginLeft: 'auto', marginRight: 'auto' }
                }
              >
                <ith-div class="ith_content">
                  {!this.props.hideHeader && (
                    <ProfileHeader
                      t={this.props.t}
                      centre={this.props.centre}
                    />
                  )}
                  <ith-div id="antd" class="ith_employeeService">
                    {!this.props.preselectedProviderSelectButHide ? (
                      <ith-div class="ith_employeeDiv">
                        {this.props.isSmallTemplate ? (
                          <ith-div class="ith_employeeServiceInputTitle">
                            {this.props.t('yourProvider')}
                          </ith-div>
                        ) : null}
                        <div>
                          <Dropdown
                            overlay={this.renderEmployeeMenu()}
                            placement="bottomCenter"
                            trigger={['click']}
                            getPopupContainer={() => document.getElementById('ith_employee')}
                            disabled={
                              this.props.preselectedProviderShowSelected
                            }
                            visible={state.employeeDropDown}
                          >
                            <ith-div
                              id="ith_employee"
                              class={`ith_employee ${this.props.preselectedProviderShowSelected ? 'ith_employee_disabled' : ''}`}
                              onClick={this.toggleDropDownEmployee}
                            >
                              {this.state.chosenProvider && (
                                <ith-div class="ith_employeeInputContent">
                                  <img
                                    className="ith_therapistAvatar"
                                    src={_.get(
                                      this.state.chosenProvider.User,
                                      'Avatar.url',
                                      `${env.api.consumerApps}/imgs/avatar.png`,
                                    )}
                                    alt="avatar"
                                  />
                                  <ith-p id="ith_therapist">
                                    {this.state.chosenProvider.User.firstName}
                                    {' '}
                                    {this.state.chosenProvider.User.lastName}
                                  </ith-p>
                                </ith-div>
                              )}
                              {state.providers.length > 1
                              && !this.props.preselectedProviderShowSelected ? (
                                <ith-div class="ith_dropDownButtonTherapist">
                                  <SVGIcon
                                    icon={
                                      state.employeeDropDown
                                        ? SmallUpArrowIcon
                                        : SmallDownArrowIcon
                                    }
                                  />
                                </ith-div>
                                ) : null}
                            </ith-div>
                          </Dropdown>
                        </div>
                      </ith-div>
                    ) : null}

                    {!this.props.preselectedServiceSelectButHide ? (
                      <ith-div class="ith_serviceDiv">
                        {this.props.isSmallTemplate ? (
                          <ith-div class="ith_employeeServiceInputTitle">
                            {this.props.t('yourService')}
                          </ith-div>
                        ) : null}
                        <div>
                          <Dropdown
                            overlay={this.renderServiceMenu()}
                            placement="bottomCenter"
                            trigger={['click']}
                            getPopupContainer={() => document.getElementById('ith_service')}
                            disabled={this.props.preselectedServiceShowSelected}
                            visible={state.serviceDropDown}
                          >
                            <ith-div
                              id="ith_service"
                              class={`ith_service ${this.props.preselectedServiceShowSelected ? 'ith_service_disabled' : ''}`}
                              onClick={this.toggleDropDownService}
                            >
                              <ith-div class="ith_serviceText">
                                <ith-p id="ith_therapyName">
                                  {(state.chosenService
                                    && `${state.chosenService.name} ${state.chosenService.duration} min`)
                                    || 'No service'}
                                </ith-p>
                                <ith-p id="ith_therapyPrice">
                                  {state.chosenService
                                    && this.getPrice(state.chosenService)}
                                </ith-p>
                              </ith-div>
                              {this.state.services.length > 0
                              && !this.props.preselectedServiceShowSelected ? (
                                <ith-div class="ith_dropDownButton ith_dropDownButtonService">
                                  <SVGIcon
                                    icon={
                                      this.state.serviceDropDown
                                        ? SmallUpArrowIcon
                                        : SmallDownArrowIcon
                                    }
                                  />
                                </ith-div>
                                ) : null}
                            </ith-div>
                          </Dropdown>
                        </div>
                      </ith-div>
                    ) : null}

                    {this.state.chosenService && this.state.chosenService.description && anchorElement ? (
                      <ith-div class="ith_service ith_serviceDescBox">
                        <ith-p class="ith_serviceDesc ith_employeeServiceInputTitle">
                          {this.state.chosenService.description}
                        </ith-p>
                      </ith-div>
                    ) : null}
                  </ith-div>

                  {this.state.chosenService && anchorElement ? (
                    <AppointmentTimePicker
                      t={this.props.t}
                      providerId={this.state.chosenProvider.centreProvider_id}
                      centreId={this.props.centre.centre_id}
                      centreBookingLimits={{
                        bookingLimitCancellationUnit:
                          this.props.centre.CentreSetting
                            .bookingLimitCancellationUnit,
                        bookingLimitCancellationValue:
                          this.props.centre.CentreSetting
                            .bookingLimitCancellationValue,
                        bookingLimitMaximumValue:
                          this.props.centre.CentreSetting
                            .bookingLimitMaximumValue,
                        bookingLimitMinimumUnit:
                          this.props.centre.CentreSetting
                            .bookingLimitMinimumUnit,
                        bookingLimitMinimumValue:
                          this.props.centre.CentreSetting
                            .bookingLimitMinimumValue,
                        bookingLimitsMaximumUnit:
                          this.props.centre.CentreSetting
                            .bookingLimitsMaximumUnit,
                      }}
                      serviceId={this.state.chosenService.service_id}
                      handler={(timeSlot, date) => this.setState({ timeSlot, date })}
                      highlightedTimeSlot={{
                        timeSlot: this.state.timeSlot,
                        date: this.state.date,
                      }}
                      apptDate={this.props.apptDate}
                      apptTime={this.props.apptTime}
                      isEmbed
                      isHiddenDisabledTimeSlot
                      isSmallTemplate={this.props.isSmallTemplate}
                    />
                  ) : null}

                  {this.state.timeSlot && (
                    <ith-button
                      class="ith_bookButton"
                      onClick={() => this.bookAppointment(this.state.timeSlot, this.state.date)}
                    >
                      {this.props.t('label_nextStep')}
                    </ith-button>
                  )}
                </ith-div>
              </ith-div>

              <style>{styles}</style>
              {this.props.isSmallTemplate ? <style>{stylesSmall}</style> : null}
            </>
          )}
        </ith-div>

        <Popover
          id="errorModal"
          onClose={() => this.setState({ showErrorModal: false })}
          open={this.state.showErrorModal}
          anchorEl={anchorEmbedStepperElement || anchorElement}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            horizontal: 'center',
          }}
        >
          <EmbedError
            message={this.state.errorMessage}
            onClose={() => this.setState({ showErrorModal: false })}
          />
        </Popover>

        {state.displayingWidget === DISPLAYING_WIDGET.BOOKING_CONFIRM
          && this.props.isModal && (
            <CheckoutContainer
              t={props.t}
              centre={state.queryData.centre}
              provider={state.queryData.provider}
              services={state.queryData.services}
              startTime={state.queryData.startTime}
              endTime={state.queryData.endTime}
              bufferTime={state.queryData.bufferTime}
              date={state.queryData.date}
              userData={state.user}
              showBookingCalendar={() => {
                this.setState({
                  displayingWidget: DISPLAYING_WIDGET.BOOKING_CALENDAR,
                });
              }}
              rescheduleBookClick={
                this.props.rescheduleBookClick
                && (() => this.props.rescheduleBookClick(
                  this.state.timeSlot,
                  this.state.date,
                ))
              }
              isModal={this.props.isModal}
              closeNewBookingModalAndGetAppts={
                this.props.closeNewBookingModalAndGetAppts
              }
              isEmbed
            />
        )}

        <ith-div class="embed_checkout_container_wrapper ith_content">
          {state.displayingWidget === DISPLAYING_WIDGET.BOOKING_CONFIRM
            && !this.props.isModal && (
              <EmbedCheckoutContainer
                t={props.t}
                centre={state.queryData.centre}
                provider={state.queryData.provider}
                services={state.queryData.services}
                startTime={state.queryData.startTime}
                endTime={state.queryData.endTime}
                bufferTime={state.queryData.bufferTime}
                date={state.queryData.date}
                userData={state.user}
                showBookingCalendar={() => {
                  this.setState({
                    displayingWidget: DISPLAYING_WIDGET.BOOKING_CALENDAR,
                  });
                }}
                onSignInSubmit={this.onSignInSubmit}
                onSignUpSubmitStep1={this.onSignUpSubmitStep1}
                onSignUpSubmitStep2={this.onSignUpSubmitStep2}
                onVerificationSubmit={this.onVerificationSubmit}
                onSendCodeSubmit={this.onSendCodeSubmit}
                errorHandler={this.errorHandler}
                activeStep={this.state.activeStep}
                handleNext={this.handleNext}
                handleBack={this.handleBack}
                handleStepChange={this.handleStepChange}
              />
          )}

          <style>{styles}</style>
          {this.props.isSmallTemplate ? <style>{stylesSmall}</style> : null}
        </ith-div>
      </>
    );
  }
}

export default EmbedBookingContainer;
