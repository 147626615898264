/* eslint-disable import/prefer-default-export */
import dashboardInterface from '../../interfaces/api/dashboard';
import {
  getAppointmentList,
  getAppointmentListF,
  getAppointmentListS,
  getInvoice,
  getInvoiceF,
  getInvoiceList,
  getInvoiceListF,
  getInvoiceListS,
  getInvoiceS,
  getUserData,
  getUserDataF,
  getUserDataS,
  resetAppointmentList,
  resetInvoice,
  resetInvoiceList,
} from '../slices/dashboard';
import { setErrorThunk } from './modals';

export const resetAppointmentListThunk = () => async (dispatch) => {
  dispatch(resetAppointmentList());
};

export const resetInvoiceListThunk = () => async (dispatch) => {
  dispatch(resetInvoiceList());
};

export const resetInvoiceThunk = () => async (dispatch) => {
  dispatch(resetInvoice());
};

export const getAppointmentListThunk = (userId) => async (dispatch) => {
  dispatch(getAppointmentList());

  await dashboardInterface
    .getAppointmentList(userId)
    .then((response) => {
      dispatch(getAppointmentListS(response));
    })
    .catch((err) => {
      dispatch(getAppointmentListF());

      console.log(err);
      dispatch(
        setErrorThunk(
          'Termine konnten nicht abgerufen werden. Versuchen Sie es später erneut.',
          err,
        ),
      );
    });
};

export const getInvoiceListThunk = (userId) => async (dispatch) => {
  dispatch(getInvoiceList());

  await dashboardInterface
    .getInvoiceList(userId)
    .then((response) => {
      dispatch(getInvoiceListS(response));
    })
    .catch((err) => {
      dispatch(getInvoiceListF());

      console.log(err);
      dispatch(
        setErrorThunk(
          'Rechnungen konnten nicht abgerufen werden. Versuchen Sie es später erneut.',
          err,
        ),
      );
    });
};

export const getInvoiceThunk = (invoiceId) => async (dispatch) => {
  dispatch(getInvoice());

  await dashboardInterface
    .getInvoice(invoiceId)
    .then((response) => {
      dispatch(getInvoiceS(response));
    })
    .catch((err) => {
      dispatch(getInvoiceF());

      console.log(err);
      dispatch(
        setErrorThunk(
          'Rechnung konnten nicht abgerufen werden. Versuchen Sie es später erneut.',
          err,
        ),
      );
    });
};

export const markInvoiceAsViewedThunk = (invoiceId) => async (dispatch) => {
  dispatch(getInvoice());
  // TODO mark as viewed here or seprate
  await dashboardInterface
    .markInvoiceAsViewed(invoiceId)
    .then(() => {
      resetInvoiceListThunk();
    })
    .catch((err) => {
      dispatch(getInvoiceF());

      console.log(err);
      dispatch(
        setErrorThunk(
          'Rechnung konnten nicht abgerufen werden. Versuchen Sie es später erneut.',
          err,
        ),
      );
    });
};

export const getInvoiceAsZIPThunk = (invoiceId) => async (dispatch) => {
  await dashboardInterface
    .getInvoiceAsZIP(invoiceId)
    .then((response) => {
      const { fileName, base64 } = response;

      const href = `data:application/zip;base64,${base64}`;
      const a = document.createElement('a');

      a.href = href;
      a.download = fileName;
      a.click();

      dispatch(markInvoiceAsViewedThunk(invoiceId));
    })
    .catch((err) => {
      console.log(err);
      dispatch(
        setErrorThunk(
          'Die Rechnung konnte nicht als ZIP abgerufen werden. Versuchen Sie es später erneut.',
          err,
        ),
      );
    });
};

export const getUserDataThunk = (userId) => async (dispatch) => {
  console.log('userId', userId);
  dispatch(getUserData());

  await dashboardInterface
    .getUserData(userId)
    .then((response) => {
      dispatch(getUserDataS(response));
    })
    .catch((err) => {
      dispatch(getUserDataF());

      console.log(err);
      dispatch(
        setErrorThunk('Die Benutzerdaten konnten nicht abgerufen werden.', err),
      );
    });
};

export const updateUserdataThunk = (userId, data) => async (dispatch) => {
  await dashboardInterface
    .updateUserData(userId, data)
    .then((response) => {
      dispatch(getUserDataS(response));
    })
    .catch((err) => {
      console.log(err);
      dispatch(
        setErrorThunk(
          'Die Benutzerdaten konnten nicht gespeichert werden. Versuchen Sie es später erneut.',
          err,
        ),
      );
    });
};
